import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Instagram from "../images/icons/instagram.svg";
import LinkedIn from "../images/icons/linkedin.svg";
import Behance from "../images/icons/behance.svg";

const ContactPage = () => (
  <Layout colouredBackground>
    <Wrapper>
      <SEO title="Contact" />
      <h1>Contact</h1>
      <p>
        Let’s talk! Contact me at{" "}
        <a href="mailto:hello@kirstyho.co.uk?subject=Hello!">
          hello@kirstyho.co.uk
        </a>
      </p>
      <IconContainer>
        <a href="https://www.instagram.com/kirstyho/" target="_blank">
          <img src={Instagram} alt="Instagram" />
        </a>
        <a href="https://www.behance.net/kirstyho" target="_blank">
          <img src={Behance} alt="Behance" />
        </a>
        <a href="https://www.linkedin.com/in/kirstyho" target="_blank">
          <img src={LinkedIn} alt="LinkedIn" />
        </a>
      </IconContainer>
    </Wrapper>
  </Layout>
);

export default ContactPage;

const Wrapper = styled.div`
  h1 {
    font-weight: bold;
    color: white;
    font-size: 2em;
  }
  padding-top: 5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    height: 50px;
    margin: 2em;
  }
  p > a {
    color: white;
  }
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;

  a {
    color: white;
  }
`;
